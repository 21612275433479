import styled from "styled-components";

export const Logo = styled.img`
  max-width: 140px;
`;

export const Slider = styled.div`
  height: 600px;
  @media (max-width: 819px) {
    height: 200px;
  }
`;

export const Hero = styled.ul`
  height: 100vh;
  width: 100%;
  background-position: center;
  background-size: cover;

  @media (max-width: 819px) {
    height: 0;
    display: none;
  }

  @media (max-width: 768px) {
    background-image: url('${props => props?.image?.medium}');
    display: none;
  }

  @media (max-width: 375px) {
    background-image: url('${props => props?.image?.small}');
    display: none;
  }
`;

export const PlansMenu = styled.div`

@media (min-width: 1200px) {
  display: flex;
  text-align: center;
  height: 100%;
  padding-left: 10px;
  align-items: center;
  padding-right: 10px;
  flex-wrap: wrap;
  justify-content: center;
  
////////////////////////////
  .arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 2px;
    margin-left: 10px;
    opacity:0.5;
    transition: all 0.4s ease;
  }
  .down {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  li:hover {
    i {
      transform: rotateZ(45deg);
    }
  }
////////////////////////////////

.container {
  display: flex;
  margin: 0 auto;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  text-align: left;
}

nav li {
  display: inline-block;
}

nav a {
  color: #444;
  text-decoration: none;
  display: block;
  padding: .75em 1.75em;
}

nav li:hover {
  background: rgb(255, 255, 255);
}

nav li:hover a {
  color: #000;
}


.menu-sub {
  position: absolute !important;
  z-index: 999;
  left: 0;
  background: rgb(255, 255, 255);
  display: none;
  color: #fff;
  padding: 2em;
  border: 1px solid rgb(211, 211, 211);
}

nav li:hover .menu-sub {
  display: block;
}

.menu-sub li {
  display: block;
}

.menu-sub a {
  padding: 0;
  margin-bottom: .35em;
}

.menu-sub a:hover {
  text-decoration: underline;
}

.menu-category {
  margin: 2.5em 0 .5em;
}

.menu-category:first-of-type {
  margin-top: 0;
}

.menu-col-1,
.menu-col-2,
.menu-col-3,
.menu-col-4 {
  float: left;
}

.menu-col-1 {
  width: 25%;
}

.menu-col-2 {
  width: 50%;
}
`;

export const Plans = styled.li`
  height: 100vh;
  width: 100%;
  padding-top: 7%;
  margin-bottom: 2%;
  display: flex;
  align-items: right;
  height: 110px;

  @media (max-width: 819px) {
    height: 0;
    display: none;
  }

  @media (max-width: 768px) {
    background-image: url('${props => props?.image?.medium}');
    display: none;
  }

  @media (max-width: 375px) {
    background-image: url('${props => props?.image?.small}');
    display: none;
  }
`;

export const Carrousel = styled.div`
  .awssld__content {
    > div {
      width: 100%;
    }
  }
`;

export const Infos = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
  margin-left: -15px;
  margin-right: -15px;

  @media (max-width: 819px) {
    // margin-top: 230px;
    flex-wrap: wrap;
  }
`;

export const Info = styled.div`
  display: flex;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: 819px) {
    width: 50%;

    &:last-child {
      margin-top: 30px;
    }
  }

  @media (max-width: 469px) {
    width: 100%;

    &:not(:first-child) {
      margin-top: 30px;
    }
  }

  div {
    margin-left: 24px;
  }

  h2 {
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    color: #222;
    font-weight: 600;
    margin-bottom: 8px;
  }

  span {
    font-size: 12px;
    line-height: 12px;
  }
`;

export const Highlights = styled.section`
  // border: 10px solid #000;
  margin: 50px 0;
  display: flex;
  text-align: center;
  padding: 20px;
  flex-wrap: wrap;
`;



export const HighlightsInfos = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  width: 30%;

  @media (max-width: 1000px) {
    width: 100%;
  }

  h2 {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 8px;
    text-transform: uppercase;
    color: #bbb;
    font-weight: 400;
  }

  h3 {
    color: #222;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  p {
    font-size: 12px;
    line-height: 22px;
    color: #666;
  }
`;

export const HighlightsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33.333333%);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1px;
  background-color: #e9e9e9;
  width: 70%;

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 819px) {
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(3, 1fr);
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
  }
`;

export const ProductBackground = styled.div`
  padding: 10px 20px;
  background-color: #fff;
`;
